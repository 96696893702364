var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('ul',{staticClass:"nav nav-tabs d-flex"},[_c('li',{class:{
        active: _vm.isActiveTab('gitlabTab'),
        'gitlab-tab': true,
        'flex-grow-1': true,
      }},[_c('a',{attrs:{"href":"#gitlab"},on:{"click":function($event){return _vm.makeActive('gitlabTab')}}},[_vm._v("Gitlab")])]),_c('li',{class:{
        active: _vm.isActiveTab('jiraTab'),
        'jira-tab': true,
        'flex-grow-1': true,
      }},[_c('a',{attrs:{"href":"#jira"},on:{"click":function($event){return _vm.makeActive('jiraTab')}}},[_vm._v("Jira")])]),_c('li',{class:{
        active: _vm.isActiveTab('reportTab'),
        'report-tab': true,
        'flex-grow-1': true,
      }},[_c('a',{attrs:{"href":"#report"},on:{"click":function($event){return _vm.makeActive('reportTab')}}},[_vm._v("Export")])]),_c('li',{class:{
        active: _vm.isActiveTab('summaryTab'),
        'summary-tab': true,
        'flex-grow-1': true,
      }},[_c('a',{attrs:{"href":"#report"},on:{"click":function($event){return _vm.makeActive('summaryTab')}}},[_vm._v("Summary")])])]),_c('div',{staticClass:"d-flex"},[(_vm.isActiveTab('gitlabTab'))?_c('div',{staticClass:"flex-grow-1"},[_c('Gitlab')],1):_vm._e(),(_vm.isActiveTab('jiraTab'))?_c('div',{staticClass:"flex-grow-1"},[_c('Jira')],1):_vm._e(),(_vm.isActiveTab('reportTab'))?_c('div',{staticClass:"flex-grow-1"},[_c('Report')],1):_vm._e(),(_vm.isActiveTab('summaryTab'))?_c('div',{staticClass:"flex-grow-1"},[_c('GitlabSummary')],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }