<template>
  <v-app>
    <ul class="nav nav-tabs d-flex">
      <li
        :class="{
          active: isActiveTab('gitlabTab'),
          'gitlab-tab': true,
          'flex-grow-1': true,
        }"
      >
        <a href="#gitlab" @click="makeActive('gitlabTab')">Gitlab</a>
      </li>
      <li
        :class="{
          active: isActiveTab('jiraTab'),
          'jira-tab': true,
          'flex-grow-1': true,
        }"
      >
        <a href="#jira" @click="makeActive('jiraTab')">Jira</a>
      </li>
      <li
        :class="{
          active: isActiveTab('reportTab'),
          'report-tab': true,
          'flex-grow-1': true,
        }"
      >
        <a href="#report" @click="makeActive('reportTab')">Export</a>
      </li>
      <li
        :class="{
          active: isActiveTab('summaryTab'),
          'summary-tab': true,
          'flex-grow-1': true,
        }"
      >
        <a href="#report" @click="makeActive('summaryTab')">Summary</a>
      </li>
    </ul>
    <div class="d-flex">
      <div v-if="isActiveTab('gitlabTab')" class="flex-grow-1">
        <Gitlab />
      </div>
      <div v-if="isActiveTab('jiraTab')" class="flex-grow-1">
        <Jira />
      </div>
      <div v-if="isActiveTab('reportTab')" class="flex-grow-1">
        <Report />
      </div>
      <div v-if="isActiveTab('summaryTab')" class="flex-grow-1">
        <GitlabSummary />
      </div>
    </div>
  </v-app>
</template>
<script>
import Gitlab from "./Gitlab.vue";
import GitlabSummary from "./GitlabSummary.vue";
import Jira from "./Jira.vue";
import Report from "./Report.vue";

export default {
  name: "TicketManagement",
  components: {
    Gitlab,
    Jira,
    Report,
    GitlabSummary,
  },
  data() {
    return {
      selectedTab: "gitlabTab",
    };
  },
  methods: {
    makeActive(tab) {
      this.selectedTab = tab;
    },
    isActiveTab(val) {
      return this.selectedTab === val;
    },
  },
};
</script>

<style scoped>
.nav-tabs {
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
  width: 25%;
  margin-left: 40px;
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-tabs > li > a {
  margin-right: 2px;
  margin-left: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.nav-tabs > li.active > a {
  /* Styles for active tab */
  /* background-color: #007bff; */
  background-color: #3f51b5;
  color: #fff; /* Change text color for better contrast */
  border-color: #3f51b5;
}
.d-flex > div {
  width: 80%; /* Set the width of the content area */
}
.nav-tabs > li {
  width: 50%; /* Each tab takes up 50% of the width */
}

.nav-tabs > li > a {
  color: #3f51b5;
}

.nav.nav-tabs.d-flex a {
  text-decoration: none;
}
</style>
