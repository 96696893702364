<template>
  <v-app>
    <div>
      <div class="loading-container" v-if="jiraLoading">
        <div class="loading-spinner"></div>
      </div>
      <div v-else class="center-float">
        <div class="table-container-jira">
          <div class="vcard">
            <v-card-title id="search-card">
              <span class="dark-text">L2 Tickets (Jira)</span>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                append-icon="mdi-tune"
                :label="searchLabel"
                single-line
                hide-details
                class="ew-tablesearch-input"
                @keydown.enter="searchText"
                @click:append="openDialog"
              >
              </v-text-field>
              <v-dialog
                v-model="isActive"
                max-width="300"
                transition="fade-transition"
              >
                <template>
                  <v-card title="Apply filter" prepend-icon="mdi-tune">
                    <v-card-text>
                      <v-col cols="12" md="12" sm="6">
                        <v-select
                          label="Search By"
                          v-model="searchField"
                          :items="['Title', 'Description', 'Issue id']"
                          variant="outlined"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="12" sm="6">
                        <DatePicker
                          label="Filter by From Date"
                          v-model="fromDate"
                          :max-date="toDate || nextDayDate"
                        />
                      </v-col>
                      <v-col cols="12" md="12" sm="6">
                        <DatePicker
                          label="Filter by To Date"
                          v-model="toDate"
                          :min-date="fromDate"
                          :max-date="nextDayDate"
                        ></DatePicker>
                      </v-col>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        :style="{ backgroundColor: 'red', color: 'white' }"
                        @click="isActive = false"
                        >Close</v-btn
                      >
                      <v-btn
                        :style="{ backgroundColor: 'blue', color: 'white' }"
                        @click="applyFilter(1)"
                        >Apply</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-card-title>
          </div>
          <p class="note">Note: Entries are sorted only within the current page. Navigate to other pages to see more tickets.</p>
          <Table :columns="columns" :data="jiraList"></Table>
        </div>
        <div id="pageSelectionJira" class="radio-toolbar-jira">
          <PageTurner
            @changePageEvent="changePage"
            :pageSize="20"
            :totalItems="totalJiraCount"
            :currentPage="selectedPage"
            :pageTurnerSize="5"
            v-if="totalJiraCount > 20"
          ></PageTurner>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EventBus from "../../../utils/EventBus";
import { jiraColumns } from "./config.js";
import Table from "../sharedcomponents/Table.vue";
import PageTurner from "../sharedcomponents/PageTurner.vue";
import DatePicker from "./DatePicker.vue";

export default {
  name: "Jira",
  components: {
    Table,
    PageTurner,
    DatePicker,
  },
  computed: {
    ...mapState([
      "jiraList",
      "totalJiraCount",
      "jiraUnlinkedKeys",
      "totalJiraUnlinkedCount",
      "jiraTicket",
    ]),
    searchLabel() {
      return `Search by ${this.searchField}...`;
    },
  },
  data: () => ({
    columns: jiraColumns,
    jiraLoading: true,
    selectedPage: 1,
    isActive: false,
    fromDate: "",
    toDate: "",
    searchField: "Title",
    search: "",
    nextDayDate: new Date(new Date().setDate(new Date().getDate() + 1))
      .toISOString()
      .slice(0, 10),
  }),
  beforeMount() {
    this.setDeploymentName(this.$route.query.platform);
  },
  async mounted() {
    EventBus.$on("clickTableEvent", this.itemClicked);
    await this.getJira(1); // Ensure data is fetched
    this.jiraLoading = false; // Hide spinner after data is fetched
  },
  methods: {
    ...mapActions(["fetchJiraList", "setDeploymentName"]),
    itemClicked(column, item) {
      if (column === "number") {
        window.open(item.url, "_blank");
      }
      if (column === "aiAssistant") {
        window.open(`./ticketManagement/jira/${item.key}`, "_blank");
      }
    },
    async getJira(page) {
      this.jiraLoading = true;
      try {
        await this.fetchJiraList({
          data: {
            page: page,
            pageSize: 20,
            projectKey: "NSD",
            // status: '\"Pending with L2 Support\"'
          },
          path: "/v1/jira/bulk",
        });
      } finally {
        this.jiraLoading = false;
      }
    },
    async changePage(number) {
      this.selectedPage = number;
      if (this.fromDate || this.toDate || this.search) {
        await this.applyFilter(this.selectedPage);
      } else {
        await this.getJira(this.selectedPage);
      }
    },
    async searchText(event) {
      if (event.key === "Enter") {
        await this.applyFilter(1);
      }
    },
    openDialog() {
      this.isActive = true;
    },
    async applyFilter(number) {
      this.jiraLoading = true;
      this.selectedPage = number;
      const searchData = {
        page: number,
        pageSize: 20,
        projectKey: "NSD",
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (this.searchField === "Title") {
        searchData.searchBy = "summary";
        searchData.searchValue = this.search;
      } else if (this.searchField === "Description") {
        searchData.searchBy = "description";
        searchData.searchValue = this.search;
      } else {
        searchData.keyList = ["NSD-" + this.search];
      }

      try {
        await this.fetchJiraList({
          data: searchData,
          path: "/v1/jira/bulk",
        });
      } finally {
        this.isActive = false;
        this.jiraLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.center-float {
  margin-top: 20px;
}

.radio-toolbar-jira {
  display: flex;
  justify-content: center;
}

.radio-toolbar-jira input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar-jira span {
  float: left;
}

.radio-toolbar-jira i {
  float: left;
  margin: 10px;
  align-self: center;
}

.radio-toolbar-jira .page-label {
  font-size: 12px;
  padding: 5px;
}

.table-container-jira {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Add semi-transparent background */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

#search-card {
  display: flex;
  justify-content: space-between;
  padding: 0 1.4rem;
}

.ew-tablesearch-input {
  max-width: 250px !important;
}
.note{
  text-align: left;
  padding-left: 1.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
