<template>
  <v-app>
    <v-container fluid class="pa-4">
      <v-row class="report-container">
        <v-col>
          <v-card class="pa-4 report-widget" tile>
            <v-card-title class="headline">Export Report</v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle class="mt-2 user-info">
              Reports are generated once daily. Click the button below to check
              for today's report or create a new one.
            </v-card-subtitle>
            <v-btn
              :disabled="loading || reportExistsForToday"
              @click="generateReport"
              color="#3f51b5"
              class="mt-4 generate-btn"
              large
              rounded
              aria-label="Generate or Fetch Report"
            >
              <v-icon v-if="!loading" left>mdi-refresh</v-icon>
              <span v-if="!loading">
                {{ reportExistsForToday ? "Report Ready" : "Fetch Report" }}
              </span>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="white"
                size="24"
                class="ml-3"
              ></v-progress-circular>
            </v-btn>
            <v-alert
              v-if="alert.show"
              :type="alert.type"
              :color="alert.color"
              class="mt-4"
              aria-live="assertive"
            >
              {{ alert.message }}
            </v-alert>
          </v-card>
          <v-card class="pa-4 status-widget" tile>
            <v-card-title class="headline">Report Status</v-card-title>
            <v-divider></v-divider>
            <v-list class="mt-2">
              <v-list-item-group v-if="localGeneratedReport">
                <!-- Headings Row -->
                <v-list-item>
                  <v-list-item-content>
                    <v-row class="heading-row mt-2 report-heading">
                      <v-col cols="3" class="text-left">
                        <h4>Report Name</h4>
                      </v-col>
                      <v-col cols="2" class="text-left">
                        <h4>Link Expiry</h4>
                      </v-col>
                      <v-col cols="2" class="text-left">
                        <h4>Report Generated</h4>
                      </v-col>
                      <v-col cols="2" class="text-left">
                        <h4>Report Size</h4>
                      </v-col>
                      <v-col cols="2" class="text-left">
                        <h4>Download</h4>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>

                <!-- Report Content Row -->
                <v-list-item
                  @click="downloadReport(localGeneratedReport.url)"
                  class="hover-effect"
                >
                  <v-list-item-content>
                    <v-row>
                      <v-col cols="3" class="text-left">
                        <v-list-item-title>{{
                          localGeneratedReport.name
                        }}</v-list-item-title>
                      </v-col>
                      <v-col cols="2" class="text-left">
                        <v-list-item-subtitle>
                          {{ displayExpiry(localGeneratedReport.expiry) }}
                        </v-list-item-subtitle>
                      </v-col>
                      <v-col cols="2" class="text-left">
                        <v-list-item-subtitle>
                          {{ localGeneratedReport.date }}
                        </v-list-item-subtitle>
                      </v-col>
                      <v-col cols="2" class="text-left">
                        <v-list-item-subtitle>
                          {{ localGeneratedReport.size }}Mb
                        </v-list-item-subtitle>
                      </v-col>
                      <v-col cols="2" class="text-left">
                        <v-icon color="#3f51b5">mdi-download</v-icon>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <!-- No Reports Available -->
              <v-alert v-else type="info" color="#3f51b5" class="mt-4">
                No reports generated yet for today or link expired. Click the
                button to generate a new report.
              </v-alert>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "Report",
  data() {
    return {
      loading: false,
      localGeneratedReport: null, // local variable to store generatedReport
      alert: {
        show: false,
        message: "",
        type: "info",
        color: "light-blue lighten-4",
      },
    };
  },
  computed: {
    ...mapState(["generatedReport"]),
    reportExistsForToday() {
      if (!this.localGeneratedReport) return false;
      const today = moment().format("DD-MM-YYYY");
      const reportDate = moment(
        this.localGeneratedReport.date,
        "DD-MM-YYYY HH:mm:ss"
      ).format("DD-MM-YYYY");
      return today === reportDate;
    },
  },
  mounted() {
    this.localGeneratedReport = this.generatedReport; // assign computed property to local variable
  },
  methods: {
    ...mapActions(["getGeneratedReport"]),

    async generateReport() {
      this.loading = true;
      this.alert.show = true;
      this.alert.message = "Processing your request...";
      this.alert.type = "info";
      this.alert.color = "#3f51b5";

      try {
        await this.getGeneratedReport({ path: `/v1/generate-report` });
        this.alert.message = "Report generated successfully!";
        this.alert.type = "success";
        this.alert.color = "green";
        this.localGeneratedReport = this.generatedReport; // update local variable after fetching report
      } catch (error) {
        this.alert.message = "Failed to generate report. Please try again.";
        this.alert.type = "error";
        this.alert.color = "red";
        console.error("Error generating report:", error);
      } finally {
        this.loading = false;
      }
    },
    downloadReport(url) {
      window.open(url, "_blank");
    },
    displayExpiry(expiryTime) {
      const now = moment();
      const expiryMoment = moment(expiryTime, "DD-MM-YYYY HH:mm:ss");
      const timeLeft = expiryMoment.diff(now, "minutes");
      if (timeLeft <= 0) {
        this.alert.show = true;
        this.alert.message =
          "Report link expired, please click on fetch report to generate new link...";
        this.alert.type = "warning";
        this.alert.color = "#ff9800";
        this.localGeneratedReport = null;
        return "expired";
      } else {
        return `${timeLeft} mins`;
      }
    },
  },
};
</script>

<style scoped>
.report-widget,
.status-widget {
  background-color: #ffffff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.report-container {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.heading-row {
  font-weight: bold;
}

.hover-effect {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.hover-effect:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transform: scale(1.02);
}

.user-info {
  padding: 0.5rem 1rem;
}

.generate-btn {
  margin-top: 0.55rem !important;
  color: #ffffff;
}
</style>
