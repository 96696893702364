<template>
<v-app>
    <TicketManagement />
</v-app>
</template>
    
<script>
import TicketManagement from '../components/TicketManagement/TicketManagement.vue'
import router from './../router/index'
import { mapState, mapActions } from 'vuex'
import { routeNames } from '../../utils/dataValidations'
export default {
    components: {
    TicketManagement
    },
    computed: {
    ...mapState([
        'isAuth',
        'isProd',
        'scopes'
    ])
    },
    mounted: function () {
    if (!this.isAuth) {
        router.push(routeNames.Home)
    }
    if (this.isProd && !this.scopes.includes('ticket-admin')) {
      router.push(routeNames.Welcome)
    }
    this.setDeploymentName(this.$route.query.platform)
    },
    methods: {
    ...mapActions([
      'setDeploymentName'
    ])
  }
}
</script>
