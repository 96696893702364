<template>
  <v-select
    v-model="selected"
    :items="items"
    :label="label"
    :item-text="itemText"
    :item-value="itemValue"
    class="ew-tablesearch-input"
    @change="emitChange"
  />
</template>

<script>
export default {
  name: "GitlabSelect",
  props: {
    selected: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    itemText: {
      type: String,
      default: null, // Default to null for plain string arrays
    },
    itemValue: {
      type: String,
      default: null, // Default to null for plain string arrays
    },
  },
  methods: {
    emitChange() {
      this.$emit("update:selected", this.selected);
      this.$emit("selection-changed");
    },
  },
};
</script>
