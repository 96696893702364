<template>
  <v-app>
    <div class="loading-container" v-if="gitlabLoading">
      <div class="loading-spinner"></div>
    </div>
    <div class="summary-container" v-else>
      <div>
        <v-card-title id="search-card">
          <span class="dark-text">Ticket Summary</span>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            append-icon="mdi-tune"
            label="Search in the table..."
            single-line
            hide-details
            class="ew-tablesearch-input"
            @click:append="openDialog"
          />
          <v-dialog
            v-model="isActive"
            max-width="300"
            transition="fade-transition"
          >
            <v-card>
              <v-card-title>Apply filter</v-card-title>
              <v-card-text>
                <v-col cols="12">
                  <DatePicker
                    label="Filter by From Date"
                    v-model="fromDate"
                    :max-date="toDate || nextDayDate"
                  />
                </v-col>
                <v-col cols="12">
                  <DatePicker
                    label="Filter by To Date"
                    v-model="toDate"
                    :min-date="fromDate"
                    :max-date="nextDayDate"
                  ></DatePicker>
                </v-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="btn-close" @click="isActive = false">Close</v-btn>
                <v-btn class="btn-apply" @click="applyFilter">Apply</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
      </div>
      <Table
        :columns="filteredColumns"
        :data="this.gitlabSummaryList"
        :hide-footer="false"
        :search="search"
      />
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Table from "../sharedcomponents/Table.vue";
import EventBus from "../../../utils/EventBus";
import DatePicker from "./DatePicker.vue";

export default {
  name: "GitlabSummary",
  components: {
    Table,
    DatePicker,
  },
  data() {
    return {
      columns: [
        {
          name: "Author",
          key: "username",
          sortable: false,
          clickable: false,
          visible: true,
        },
        {
          name: "User Id",
          key: "userId",
          sortable: false,
          clickable: true,
          visible: true,
        },
        {
          name: "Total tickets created",
          key: "totalCreatedTickets",
          sortable: true,
          clickable: false,
          visible: true,
        },
        {
          name: "Total open tickets",
          key: "totalOpenTickets",
          sortable: true,
          clickable: true,
          width: "8%",
          visible: true,
        },
        {
          name: "Total closed tickets",
          key: "totalClosedTickets",
          sortable: true,
          clickable: false,
          width: "8%",
          visible: true,
        },
      ],
      gitlabLoading: true,
      search: "",
      isActive: false,
      fromDate: "",
      toDate: "",
      nextDayDate: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .slice(0, 10),
    };
  },
  computed: {
    ...mapState(["gitlabSummaryList"]),
    filteredColumns() {
      return this.columns.filter((column) => column.visible);
    },
  },
  mounted() {
    this.fetchGitlabSummary();
    EventBus.$on("clickTableEvent", this.itemClicked);
  },
  methods: {
    ...mapActions(["fetchGitlabSummaryList"]),
    async fetchGitlabSummary() {
      this.gitlabLoading = true;
      try {
        await this.fetchGitlabSummaryList({
          data: {},
          path: `/v1/gitlab-summary`,
        });
      } catch (error) {
        console.error("Error fetching GitLab summary:", error);
      } finally {
        this.gitlabLoading = false;
      }
    },
    itemClicked(column, item) {
      if (column === "userId") {
        window.open("https://www.gitlab.com/" + item.userId, "_blank");
      }
    },
    openDialog() {
      this.isActive = true;
    },
    async applyFilter() {
      this.gitlabLoading = true;
      const filterData = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      await this.fetchGitlabSummaryList({
        data: filterData,
        path: `/v1/gitlab-summary`,
      });
      this.isActive = false;
      this.gitlabLoading = false;
    },
  },
};
</script>

<style scoped>
.summary-container {
  padding: 20px;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

#search-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem;
}

.ew-tablesearch-input {
  max-width: 250px !important;
}

.btn-close {
  background-color: red !important;
  color: white;
}

.btn-apply {
  background-color: blue !important;
  color: white;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
