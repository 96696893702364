<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        clearable
        :value="formattedDate"
        v-on="on"
         @click:clear="clearDate"
      ></v-text-field>
    </template>
    <v-date-picker
      locale="en-in"
      v-model="internalDate"
      no-title
      @input="onDateSelected"
      :max="maxDate"
      :min="minDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { formatDate } from "../../../utils/TicketMangementUtils/utils";
export default {
  name: "DatePicker",
  props: {
    label: String,
    value: String,
    maxDate: String,
    minDate: String,
  },
  data() {
    return {
      menu: false,
      internalDate: this.value,
    };
  },
  computed: {
    formattedDate() {
      return formatDate(this.internalDate);
    },
  },
  methods: {
    onDateSelected(selectedDate) {
      this.internalDate = selectedDate;
      this.$emit("input", selectedDate);
      this.menu = false;
    },
    clearDate() {
      this.internalDate = "";
      this.$emit("input", "");
    }
  },
  watch: {
    value(newValue) {
      this.internalDate = newValue;
    },
  },
};
</script>
