// utils.js

export function formatDate(date) {
  return date
    ? new Date(date).toLocaleDateString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";
}
