<template>
  <div>
    <ul class="pagination">
      <li
        v-if="selectedPage > 1"
        @click="changePage(1)"
        style="border-left: none"
      >
        <a>
          <font-awesome-icon icon="angle-double-left" />
        </a>
      </li>
      <li v-if="selectedPage > 1" @click="changePage(selectedPage - 1)">
        <a>
          <font-awesome-icon icon="angle-left" />
        </a>
      </li>
      <li
        :class="{ active: page === selectedPage }"
        v-for="page in pagesShown"
        :key="page"
        @click="changePage(page)"
      >
        <a>{{ page }}</a>
      </li>
      <li
        v-if="selectedPage < totalPages"
        @click="changePage(selectedPage + 1)"
      >
        <a>
          <font-awesome-icon icon="angle-right" />
        </a>
      </li>
      <li v-if="selectedPage < totalPages" @click="changePage(totalPages)">
        <a>
          <font-awesome-icon icon="angle-double-right" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PageTurner",
  props: {
    pageSize: {
      type: Number,
      required: true,
    },
    pageTurnerSize: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectedPage: this.currentPage,
    };
  },
  computed: {
    paginationStartFrom() {
      if (this.selectedPage < this.pageTurnerSize) {
        return 1;
      }
      return Math.max(
        1,
        this.selectedPage - Math.floor(this.pageTurnerSize / 2)
      );
    },
    paginationEnd() {
      const end = this.paginationStartFrom + (this.pageTurnerSize - 1);
      return Math.min(end, this.totalPages);
    },
    pagesShown() {
      const allPages = [];
      for (let i = this.paginationStartFrom; i <= this.paginationEnd; i++) {
        allPages.push(i);
      }
      return allPages;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
  },
  methods: {
    changePage(number) {
      if (
        number < 1 ||
        number > this.totalPages ||
        number === this.selectedPage
      ) {
        return;
      }
      this.selectedPage = number;
      this.$emit("changePageEvent", number);
    },
  },
  watch: {
    currentPage(newValue) {
      this.selectedPage = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  padding-top: 10px;
  list-style-type: none;
  display: inline-block;
  font-weight: bolder;
}

.pagination li {
  float: left;
  border-left: none;
  padding: 8px 16px;
  color: darkgray;
  text-decoration: none;
  transition: 0.3s;
}

.pagination li:first-child {
  border-left: 1px solid darkgray;
}

.pagination li.active {
  background-color: darkgray;
  color: white;
}

.pagination li:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination li:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination li:hover:not(.active) {
  background-color: darkgray;
}

.pagination li:hover {
  cursor: pointer;
}
</style>
